import validation from "./modules/validation";
import athletes from "./modules/athletes";
import vote from "./modules/vote";
import initHeights from "./modules/fixHeights";

export default () => {
  $(() => {
    const fixHeights = initHeights();
    athletes(validation, fixHeights);
    vote(validation);
    $(".video").click(() => {
      SimpleLightbox.open({
        content: `<div class="contentInPopup text-center">
              <video autoplay width="85%" height="auto" controls>
              <source src="../../assets/videos/brooke_q_a.mp4" type="video/mp4">
                Your browser does not support the video tag.
          </video></div>`,
        elementClass: "video"
      });
    });
    $(".image-gallery .gallery-item").simpleLightbox();

    var slideIndex = 1;
    showDivs(slideIndex);

    window.plusDivs = function(n) {
      showDivs((slideIndex += n));
    };

    function showDivs(n) {
      var i;
      var x = document.getElementsByClassName("mySlides");
      if (n > x.length) {
        slideIndex = 1;
      }
      if (n < 1) {
        slideIndex = x.length;
      }
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
      }
      x[slideIndex - 1].style.display = "block";
    }
  });
};

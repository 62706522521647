export const getService = (
  url,
  data,
  method = 'GET',
  success = () => {},
  error = () => {}
) =>
  $.ajax({
    url: `${url}`,
    method,
    type: method,
    dataType: 'json',
    data,
    success,
    error
  });

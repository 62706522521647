import { prefix } from '../helpers/constants';

export default () => {
  $(window).on('resize', function() {
    fixHeight();
  });

  const fixHeight = () => {
    const $resultContainer = $('.results-wrapper');

    let $athletes = $resultContainer.find(`.${prefix}-c-athlete`);

    if ($resultContainer.find('.copy').length && $athletes.height() > 380) {
      $athletes.matchHeight();
    }
  };
  return fixHeight;
};

module.exports = {
  prefix: 'chf',
  options: {
    title: 'People’s Choice Award',
    description: 'People’s Choice Award',
    home: {
      entireHeader: true,
      entireFooter: true
    }
  },
  infoAthletes: [
    {
      id: 1,
      image: 'assets/images/athletes/AndreDeGrasse.jpg'
    },
    {
      id: 2,
      image: 'assets/images/athletes/BrookHenderson.jpg'
    },
    {
      id: 3,
      image: 'assets/images/athletes/MarieMicheleGangon.jpg'
    },
    {
      id: 4,
      image: 'assets/images/athletes/KiaNurse.jpg'
    },
    {
      id: 5,
      image: 'assets/images/athletes/MarkMckmorris.jpg'
    },
    {
      id: 6,
      image: 'assets/images/athletes/JenniferJones.jpg'
    },
    {
      id: 7,
      image: 'assets/images/athletes/GrahamDelaet.jpg'
    },
    {
      id: 8,
      image: 'assets/images/athletes/RyanStraschnitzki.jpg'
    },
    {
      id: 9,
      image: 'assets/images/athletes/MakenaHenry.jpg'
    },
    {
      id: 10,
      image: 'assets/images/athletes/RebeccaJohnston.jpg'
    },
    {
      id: 11,
      image: 'assets/images/athletes/ConnorMcDavid.jpg'
    },
    {
      id: 12,
      image: 'assets/images/athletes/EmilyBatty.jpg'
    },
    {
      id: 13,
      image: 'assets/images/athletes/MikaelKingsbury.jpg'
    },
    {
      id: 14,
      image: 'assets/images/athletes/PennyOleksiak.jpg'
    },
    {
      id: 15,
      image: 'assets/images/athletes/StephanieLabbe.jpg'
    },
    {
      id: 16,
      image: 'assets/images/athletes/ChrisBoucher.jpg'
    }
  ]
};

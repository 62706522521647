import $ from "jquery";
import "jquery-validation";
import "jquery-match-height";
import SimpleLightbox from "simple-lightbox";
import "bootstrap";
import "normalize.css";
import "./assets/styles/main.scss";
import main from "./assets/scripts/main";

SimpleLightbox.registerAsJqueryPlugin($);

$(document).ready(() => {
  SimpleLightbox.registerAsJqueryPlugin($);
  main();
});

import { prefix } from '../helpers/constants';

export default () => {
  const $radioButton = $('.radio');
  const $athleteInputs = $('.athlete-input');
  const $emailInput = $('#email');
  const $submitInput = $('#vote-submit');

  $athleteInputs.on('change', function() {
    return validateOptions();
  });

  $radioButton.on('change', function(e) {
    return validateOptions();
  });

  $emailInput.on('change', function(e) {
    return validateOptions();
  });

  $submitInput.on('click', function(e) {
    return validateOptions();
  });

  const validateOptions = () => {
    const $selectedAthlete = $('.athlete-input:checked');
    const $uncheckedAthlete = $('.athlete-input:not(:checked)');
    const $uncheckedItems = $uncheckedAthlete
      .parents('.votation')
      .siblings(`.${prefix}-c-athlete`);

    if ($selectedAthlete.length < 4) {
      $uncheckedItems.find('.image-athlete').css('opacity', '1');
      $uncheckedAthlete.siblings('.label').css('opacity', '1');
      $uncheckedAthlete.prop('disabled', false);
      $('.vote-h3').html('You must select 4 athletes');
      return false;
    }

    if ($selectedAthlete.length === 4) {
      $uncheckedItems.find('.image-athlete').css('opacity', '0.6');
      $uncheckedAthlete.siblings('.label').css('opacity', '0.6');
      $uncheckedAthlete.prop('disabled', true);
      $('.vote-h3').html('Selection Complete');
    }

    const selected = $.map($selectedAthlete, val => $(val).data('athleteid'));

    if (!$radioButton.find('input').prop('checked')) {
      $('.error').html('You must agree with the terms and conditions');
      return false;
    } else {
      $('.submit-section #email').prop('disabled', false);
    }

    const selectedEmail = $emailInput.val();

    if ($radioButton.find('input').prop('checked') && selectedEmail === '') {
      $('.error').html('Please enter an email');
      return false;
    }

    if (!validateEmail(selectedEmail) && selectedEmail !== '') {
      $('.error').html('Invalid email');
      return false;
    }

    $('.error').html('');
    return {
      athletes: selected,
      voter_email: $emailInput.val()
    };
  };

  const validateEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  return validateOptions();
};

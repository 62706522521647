import { apiSendVote } from '../helpers/config';
import { getService } from '../helpers/apiService';

export default validate => {
  const submitInput = $('#vote-submit');

  submitInput.click(() => {
    const data = validate();
    const $selectedAthlete = $('.athlete-input:checked');

    if (data) {
      getService(
        apiSendVote,
        data,
        'POST',
        status => {
          if (status === 'Vote stored') {
            $selectedAthlete.prop('disabled', true);
            $('.vote-h3').html('Votes submitted');
            $('.vote-h2').html('Thank you for voting');
            $('.vote-p').html('The winner will be announced at the Induction Festival on October 23.');
            $('.submit-hide').hide();
          }
        },
        error => {
          $('.error ').html(error.responseJSON.error);
        }
      );
    }
  });
};

import { apiAthletes } from "../helpers/config";
import { getService } from "../helpers/apiService";
import { prefix } from "../helpers/constants";
import { infoAthletes } from "../../../data/data";

const itemTemplate = (item, prefix, info, index) => `
  <div class="col-12 col-md-6 col-xl-3">
    <label for="athlete${item.id}">
        <div class="${prefix}-c-athlete id-${item.id}">
            <div>
                <div class="row">
                    <div class="col-12">
                        <div>
                            <img class="img-fluid image-athlete" src="${
                              info.image
                            }">
                            <h4 class='sport-name-${index}'>${
  item.category.title
}</h4>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h2 class="first-name">${item.name}</h2>
                        <h2 class="last-name">${convertLastName(
                          item.lastname
                        )}</h2>
                    </div>
                </div>
                <div class="row copy">
                    <div class="col-12 col-md-6">
                        <h3>Champion of</h3>
                        <p>${item.championof}</p>
                    </div>
                    <div class="col-12 col-md-6">
                        <h3>Champion for</h3>
                        <p>${item.championfor}</p>
                    </div>
                </div>
            </div>
        </div>
    </label>
  </div>`;

const getData = athletes => {
  let items = [];
  let index = 0;
  for (let item of athletes) {
    items.push(itemTemplate(item, prefix, infoAthletes[item.id - 1], index));
    index++;
  }

  return items.join("");
};

const convertLastName = lastName => lastName.toUpperCase().replace("MC", "Mc");

export default (validate, fixHeights) => {
  const $resultContainer = $(".results-wrapper");

  getService(apiAthletes).done(data => {
    $resultContainer.append(getData(data));
    setTimeout(fixHeights, 500);
    setTimeout(fixHeights, 1000);
    setTimeout(fixHeights, 3000);
    validate();
  });
};
